<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <b-row>
        <b-col md="12">
          <div class="card p-2 bg-blue">
            <div class="d-flex justify-content-between align-items-center">
              <h3 class="mb-0 text-white">
                {{ $t("key-130") }}
              </h3>
              <router-link :to="{ name: 'order-list' }">
                <span class="text-white">
                  <i class="far fa-backspace" /> {{ $t("key-126") }}
                </span>
              </router-link>
            </div>

            <div class="card p-2 mt-1">
              <div class="anan-order">
                <div class="row">
                  <div class="col-md-6">
                    <table>
                      <tbody>
                        <tr class="anan-order-row">
                          <td class="" style="width: 40%">
                            <p class="txt-odr mb-0">
                              {{ $t("orderNumber") }}
                            </p>
                          </td>
                          <td class="text-left pl-1" style="width: 60%">
                            <p class="txt-odr mb-0">
                              ORD-{{ details.orderId_text }}
                            </p>
                          </td>
                        </tr>
                        <tr class="anan-order-row">
                          <td class="" style="width: 40%">
                            <p class="txt-odr mb-0">Ref.</p>
                          </td>
                          <td class="text-left pl-1" style="width: 60%">
                            <p class="txt-odr mb-0">
                              {{ details.username }}#{{ details.orderId_text }}
                              {{ details.delivery_type == 1 ? "" : "(SEA)" }}
                              {{
                                details.shops.some(
                                  (ele) => ele.packbox === true
                                ) &&
                                details.shops.some(
                                  (ele) => ele.checkproduct === true
                                )
                                  ? "(QC)(打木架)"
                                  : details.shops.some(
                                      (ele) => ele.packbox === true
                                    )
                                  ? "(打木架)"
                                  : details.shops.some(
                                      (ele) => ele.checkproduct === true
                                    )
                                  ? "(QC)"
                                  : ""
                              }}
                            </p>
                          </td>
                        </tr>

                        <tr class="anan-order-row">
                          <td class="" style="width: 40%">
                            <p class="txt-odr mb-0">
                              {{ $t("customerCode") }} :
                            </p>
                          </td>
                          <td class="text-left pl-1" style="width: 60%">
                            <p class="txt-odr mb-0">
                              <b-link :to="{ name: 'admin-customer-detail' }">
                                {{ details.username }}
                              </b-link>
                            </p>
                          </td>
                        </tr>

                        <tr class="anan-order-row">
                          <td style="width: 40%">
                            <p class="txt-odr mb-0">{{ $t("mnemonic") }} :</p>
                          </td>
                          <td class="text-left pl-1" style="width: 60%">
                            <p class="txt-odr mb-0">
                              {{ details.note }}
                            </p>
                          </td>
                        </tr>

                        <tr class="anan-order-row">
                          <td class="" style="width: 40%">
                            <p class="txt-odr mb-0">
                              {{ $t("deliveryType") }} :
                            </p>
                          </td>
                          <td class="text-left pl-1" style="width: 60%">
                            <div v-if="details.delivery_type == 2">
                              {{ $t("byship") }} <i class="fas fa-ship" />
                            </div>

                            <div v-else>
                              {{ $t("byTruck") }}
                              <i class="fas fa-shipping-fast" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-md-6">
                    <table>
                      <tbody>
                        <tr class="anan-order-row">
                          <td class="text-right" style="width: 40%">
                            <p class="txt-odr mb-0">
                              {{ $t("status") }}
                            </p>
                          </td>
                          <td class="text-left pl-1" style="width: 60%">
                            <p class="txt-odr mb-0">
                              <b-badge
                                v-if="details.status === 1"
                                variant="light-warning"
                              >
                                {{ $t("pendingReview") }}
                              </b-badge>

                              <b-badge
                                v-if="details.status === 2"
                                variant="light-danger"
                              >
                                {{ $t("key-96") }}
                              </b-badge>

                              <b-badge
                                v-if="details.status === 3"
                                variant="light-info"
                              >
                                {{ $t("paidAmount") }}
                              </b-badge>

                              <b-badge
                                v-if="details.status === 4"
                                variant="light-success"
                              >
                                {{ $t("orderSuccess") }}
                              </b-badge>

                              <b-badge
                                v-if="details.status === 5"
                                variant="light-info"
                              >
                                {{ $t("fullTrack") }}
                              </b-badge>

                              <b-badge
                                v-if="details.status === 6"
                                variant="light-info"
                              >
                                {{ $t("key-142") }}
                              </b-badge>

                              <b-badge
                                v-if="details.status === 7"
                                variant="light-danger"
                              >
                                {{ $t("cancel") }}
                              </b-badge>
                              <span
                                v-if="details.status === 7"
                                class="text-danger"
                              >
                                {{
                                  details.cancel_remark
                                    ? `* ${details.cancel_remark}`
                                    : ""
                                }}
                              </span>

                              <b-badge
                                v-if="details.status === 8"
                                variant="light-warning"
                              >
                                {{ $t("leftChinaWarehouse") }}
                              </b-badge>
                              <b-badge
                                v-if="details.status === 9"
                                variant="light-warning"
                              >
                                {{ $t("arrivedThaiWarehouse") }}
                              </b-badge>
                            </p>
                          </td>
                        </tr>

                        <tr class="anan-order-row">
                          <td class="text-right" style="width: 40%">
                            <p class="txt-odr mb-0">
                              {{ $t("transactionDate") }}
                            </p>
                          </td>
                          <td class="text-left pl-1" style="width: 60%">
                            <p class="txt-odr mb-0">
                              {{ details.created_at }}
                            </p>
                          </td>
                        </tr>

                        <tr class="anan-order-row">
                          <td class="text-right" style="width: 40%">
                            <p class="txt-odr mb-0">
                              {{ $t("exRate") }}
                            </p>
                          </td>
                          <td class="text-left pl-1" style="width: 60%">
                            <p class="txt-odr mb-0">
                              ¥1 = {{ Commas(details.rate_yuan) }} ฿
                              <!-- <span>
                              <feather-icon
                                icon="Edit3Icon"
                                size="18"
                                class="cursor-pointer text-primary"
                                @click="showModal"
                              />
                            </span> -->
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col-md-6 my-2 px-2">
                    <h5>{{ $t("orderNoteUser") }}</h5>

                    <b-form-textarea
                      v-model="details.remark_customer"
                      row="2"
                      @change="UpdateDetail()"
                    />
                  </div>

                  <!-- <div class="col-md-6 my-2 px-2">
                    <h5>หมายเหตุรายการสั่งซื้อ (ผู้ดูแล)</h5>

                    <b-form-textarea
                      v-model="details.remark_admin"
                      row="2"
                      disabled
                    />
                  </div> -->

                  <div class="col-md-12">
                    <!-- <p>
                      {{ $t('inspector') }} : <b-link :to="{name : 'admin-customer-detail'}">
                        {{ details.inspector ? details.inspector: '-' }}
                      </b-link>
                    </p> -->

                    <p>{{ $t("lastEditDate") }} : {{ details.updated_at }}</p>
                    <div>
                      <button
                        v-if="details.status === 2"
                        class="stardust-button col-sm-12 col-md-2 stardust-button--primary WgYvse mr-1"
                        @click="Pay(details)"
                      >
                        {{ $t("makePayment") }}
                      </button>
                      <button
                        v-if="details.status === 1"
                        class="stardust-button col-sm-12 col-md-2 mt-1 mt-md-0 stardust-button--secondary WgYvse"
                        @click="CancelOrder(details._id)"
                      >
                        {{ $t("cancelOrder") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="12">
          <div class="card p-2">
            <h3 class="mb-0 font-weight-bolder">
              {{ $t("listProducts") }}
            </h3>

            <div
              v-for="(item, index) in details.shops"
              :key="item"
              class="mb-2"
            >
              <div>
                <div class="mt-1">
                  <table style="width: 100%" c>
                    <tbody>
                      <tr class="anan-order-row">
                        <td style="width: 25%">
                          <p class="txt-odrx mb-0">
                            {{ $t("key-131") }}
                          </p>
                        </td>

                        <!-- <td
                    class=""
                    style="width: 25%;"
                  >
                    <p class="txt-odrx mb-0 d-flex align-items-center">
                      <span class="mr5">ประเภท</span>
                      <b-form-select
                        v-model="type"
                        :options="typesOp"
                        size="sm"
                        class="w-25"
                      />
                    </p>
                  </td> -->

                        <td class="" style="width: 10%">
                          <b-form-checkbox
                            v-model="item.packbox"
                            name="check-button"
                            :value="true"
                            disabled
                            @change="UpdateDetail(details.shops[index].packbox)"
                          >
                            <p class="txt-odrx mb-0">
                              {{ $t("woodPacking") }}
                            </p>
                          </b-form-checkbox>
                        </td>

                        <td class="" style="width: 10%">
                          <b-form-checkbox
                            v-model="item.checkproduct"
                            name="check-button"
                            :value="true"
                            disabled
                            @change="
                              UpdateDetail(details.shops[index].checkproduct)
                            "
                          >
                            <p class="txt-odrx mb-0">
                              {{ $t("checkOrder") }}
                            </p>
                          </b-form-checkbox>
                        </td>

                        <!-- <td
                          class=""
                          style="width: 10%;"
                        >
                          <b-form-checkbox
                            v-model="item.re_wrap"
                            name="check-button"
                            :value="true"
                            disabled
                            @change="UpdateDetail(details.shops[index].re_wrap)"
                          >
                            <p class="txt-odrx mb-0">
                              ห่อใหม่
                            </p>
                          </b-form-checkbox>
                        </td>

                        <td
                          class=""
                          style="width: 10%;"
                        >
                          <b-form-checkbox
                            v-model="item.cashOndelivery"
                            name="check-button"
                            :value="true"
                            disabled
                            @change="UpdateDetail(details.shops[index].cashOndelivery)"
                          >
                            <p class="txt-odrx mb-0">
                              เก็บเงินปลายทาง
                            </p>
                          </b-form-checkbox>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="table-container">
                  <table style="width: 100%">
                    <!-- colgroup -->

                    <thead>
                      <tr
                        style="
                          background-color: rgb(215, 233, 250);
                          border-radius: 10px !important;
                        "
                      >
                        <th class="p-1">
                          <div class="d-flex align-items-center">
                            <span> {{ index + 1 }}. {{ item.storename }}</span>

                            <!-- <b-form-input
                          v-model="tag"
                          type="text"
                          size="sm"
                          class="ml-1 w-50"
                        /> -->
                          </div>
                        </th>

                        <th class="text-center">
                          {{ $t("quantity") }}
                        </th>

                        <th class="text-center">{{ $t("PerItem") }} [¥]</th>

                        <th class="text-center">{{ $t("total") }} [¥]</th>

                        <th class="text-center">{{ $t("total") }} [฿]</th>

                        <!-- <th class="text-center">
                      จัดการ
                    </th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(itemsproduct, indexx) in item.products"
                        :key="indexx"
                        class="anan-order-row"
                        :style="`background-color: ${itemsproduct.colorfield};`"
                      >
                        <td class="d-flex align-items-center p-1">
                          <span>{{ index + 1 }}.{{ indexx + 1 }}</span>

                          <img
                            :src="
                              itemsproduct.color_img
                                ? itemsproduct.color_img
                                : itemsproduct.picture
                            "
                            alt="asd"
                            height="50px"
                            class="ml-2 rounded mr5"
                            @click="
                              showPicture(
                                itemsproduct.color_img
                                  ? itemsproduct.color_img
                                  : itemsproduct.picture
                              )
                            "
                          />

                          <div>
                            <a :href="itemsproduct.url" target="_blank">
                              <h5 class="mb-0 JB57cn2">
                                {{ itemsproduct.name }}
                              </h5>
                            </a>
                            <p class="mb-0">
                              {{ $t("color") }} :
                              {{
                                itemsproduct.color_title
                                  ? itemsproduct.color_title
                                  : "-"
                              }}
                            </p>
                            <p class="mb-0">
                              {{ $t("size") }} :
                              {{
                                itemsproduct.size_title
                                  ? itemsproduct.size_title
                                  : "-"
                              }}
                            </p>
                            <p
                              class="mb-0"
                              style="font-weight: bold; color: red"
                              v-if="itemsproduct.remark_product"
                            >
                              {{ $t("remarks") }} :
                              {{
                                itemsproduct.remark_product
                                  ? itemsproduct.remark_product
                                  : ""
                              }}
                            </p>
                            <p
                              v-if="itemsproduct.fileimg"
                              style="font-weight: bold"
                            >
                              ไฟล์แนบ:

                              <img
                                :src="`https://api.anan-cargo.com/api/Order/getimg/${itemsproduct.fileimg}`"
                                alt="Preview"
                                width="50"
                                class="text-center"
                                @click="
                                  showPicture(
                                    `https://api.anan-cargo.com/api/Order/getimg/${itemsproduct.fileimg}`
                                  )
                                "
                              />
                              <!-- <img
                                  :src="`http://127.0.0.1:4444/api/Order/getimg/${itemsproduct.fileimg}`"
                                  alt="Preview"
                                  width="50"
                                  class="text-center"
                                  @click="showPicture(`http://127.0.0.1:4444/api/Order/getimg/${itemsproduct.fileimg}`)"
                                > -->
                            </p>
                            <p
                              v-if="itemsproduct.remark"
                              style="font-weight: bold"
                            >
                              {{ $t("remarks") }}: {{ itemsproduct.remark }}
                            </p>
                            <div
                              v-if="
                                itemsproduct.more_payment.remark ||
                                itemsproduct.more_payment.datas.length > 0
                              "
                              class="text-danger"
                            >
                              <p
                                v-if="itemsproduct.more_payment.remark"
                                class="mb-0"
                              >
                                {{ $t("remarks") }} :
                                {{ itemsproduct.more_payment.remark }}
                              </p>
                              <div
                                v-if="
                                  itemsproduct.more_payment.datas.length > 0
                                "
                              >
                                <p
                                  v-for="(item_morepay, index) in itemsproduct
                                    .more_payment.datas"
                                  :key="index"
                                  class="mb-0"
                                >
                                  {{
                                    item_morepay.payment_type == 1
                                      ? $t("saveMoremoney")
                                      : $t("Refunds")
                                  }}
                                  : {{ Commas(item_morepay.amount) }}
                                  {{ $t("baht") }}
                                </p>
                                <p
                                  v-if="
                                    itemsproduct.more_payment.datas
                                      .filter((ele) => ele.payment_type == 1)
                                      .reduce(
                                        (acc, ele) => acc + ele.amount,
                                        0
                                      ) >
                                    itemsproduct.more_payment.datas
                                      .filter((ele) => ele.payment_type == 2)
                                      .reduce((acc, ele) => acc + ele.amount, 0)
                                  "
                                  style="font-weight: 800"
                                >
                                  {{ $t("extraMoney") }} :
                                  {{
                                    Commas(
                                      itemsproduct.more_payment.datas
                                        .filter((ele) => ele.payment_type == 1)
                                        .reduce(
                                          (acc, ele) => acc + ele.amount,
                                          0
                                        ) -
                                        itemsproduct.more_payment.datas
                                          .filter(
                                            (ele) => ele.payment_type == 2
                                          )
                                          .reduce(
                                            (acc, ele) => acc + ele.amount,
                                            0
                                          )
                                    )
                                  }}
                                  {{ $t("baht") }}
                                </p>
                                <p v-else>
                                  {{ $t("totalRefunds") }} :
                                  {{
                                    Commas(
                                      itemsproduct.more_payment.datas
                                        .filter((ele) => ele.payment_type == 2)
                                        .reduce(
                                          (acc, ele) => acc + ele.amount,
                                          0
                                        ) -
                                        itemsproduct.more_payment.datas
                                          .filter(
                                            (ele) => ele.payment_type == 1
                                          )
                                          .reduce(
                                            (acc, ele) => acc + ele.amount,
                                            0
                                          )
                                    )
                                  }}
                                  {{ $t("baht") }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td class="text-center" style="vertical-align: middle">
                          <p class="mb-0">
                            {{
                              itemsproduct.quantity
                                ? Commas(itemsproduct.quantity)
                                : 0
                            }}
                          </p>
                        </td>

                        <td class="text-center" style="vertical-align: middle">
                          <p class="mb-0">
                            {{
                              itemsproduct.price
                                ? Commas(itemsproduct.price)
                                : "0.00"
                            }}
                          </p>
                        </td>

                        <td class="text-center" style="vertical-align: middle">
                          <p class="mb-0">
                            {{
                              Commas(
                                itemsproduct.quantity *
                                  Number(itemsproduct.price)
                              )
                            }}
                          </p>
                        </td>

                        <td class="text-center" style="vertical-align: middle">
                          <p class="mb-0">
                            {{
                              Commas(
                                itemsproduct.quantity *
                                  Number(itemsproduct.price) *
                                  details.rate_yuan
                              )
                            }}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-info mt-1 w-100">
                  <div class="row">
                    <div class="col-md-8 col-lg-8 col-sm-12">
                      <!-- {{ item.postnumber }} -->
                      <span v-if="!item.show">
                        <span
                          v-for="ItPost in item.postnumber"
                          :key="ItPost"
                          @click="showPicturePost(ItPost.path_img)"
                        >
                          <span
                            v-for="ItPostImg in ItPost.path_img"
                            :key="ItPostImg"
                            class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                          >
                            <img
                              :src="GetImg('postnumber_img', ItPostImg)"
                              alt="asd"
                              class="rounded mr5"
                            />
                          </span>
                        </span>
                      </span>
                    </div>

                    <div class="col-md-4 col-lg-4 col-sm-12">
                      <div>
                        <table style="width: 100%">
                          <!-- colgroup -->
                          <colgroup>
                            <col style="width: 50%" />
                            <col style="width: 25%" />
                            <col style="width: 25%" />
                          </colgroup>

                          <tbody>
                            <tr>
                              <td>
                                <p class="mb-0 font-weight-bolder">
                                  {{ $t("totalProductprice") }}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success">
                                  ¥
                                  {{
                                    Commas(
                                      item.products.reduce(
                                        (acc, ele) =>
                                          acc + ele.price * ele.quantity,
                                        0
                                      )
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿
                                  {{
                                    Commas(
                                      item.products.reduce(
                                        (acc, ele) =>
                                          acc + ele.price * ele.quantity,
                                        0
                                      ) * details.rate_yuan
                                    )
                                  }}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t("shippingInchina") }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success">
                                  ¥
                                  {{
                                    Commas(
                                      item.shipping_cnfee
                                        ? item.shipping_cnfee.toFixed(2)
                                        : "0.00"
                                    )
                                  }}
                                </p>
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿
                                  {{
                                    item.shipping_cnfee
                                      ? Commas(
                                          item.shipping_cnfee *
                                            details.rate_yuan
                                        )
                                      : "0.00"
                                  }}
                                </p>
                              </td>
                            </tr>

                            <!-- <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t('woodenCrates') }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.packaging_fee), 0)) }}
                                </p>
                              </td>
                            </tr> -->
                            <!-- <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t('ProductInspectionfee') }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.product_inspection_fee), 0)) }}
                                </p>
                              </td>
                            </tr> -->

                            <!-- <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t('key-138') }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas(item.postnumber.reduce((acc, ele) => acc + (ele.re_wrap_fee), 0)) }}
                                </p>
                              </td>
                            </tr> -->

                            <!-- <tr>
                              <td>
                                <p class="mb-0">
                                  {{ $t('cashOndelivery') }} (฿)
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 font-weight-bolder text-success" />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿ {{ Commas(item.cashOndelivery_fee ? item.cashOndelivery_fee.toFixed(2): '0.00') }}
                                </p>
                              </td>
                            </tr> -->

                            <tr>
                              <td>
                                <p class="mb-0 font-weight-bolder">
                                  {{ $t("totalPrices") }}
                                </p>
                              </td>
                              <td>
                                <p
                                  class="mb-0 font-weight-bolder text-success"
                                />
                              </td>
                              <td class="text-right">
                                <p class="mb-0 font-weight-bolder text-primary">
                                  ฿
                                  {{
                                    Commas(
                                      item.products.reduce(
                                        (acc, ele) =>
                                          acc + ele.price * ele.quantity,
                                        0
                                      ) *
                                        details.rate_yuan +
                                        item.postnumber.reduce(
                                          (acc, ele) => acc + ele.packaging_fee,
                                          0
                                        ) +
                                        item.postnumber.reduce(
                                          (acc, ele) =>
                                            acc + ele.product_inspection_fee,
                                          0
                                        ) +
                                        item.postnumber.reduce(
                                          (acc, ele) => acc + ele.re_wrap_fee,
                                          0
                                        ) +
                                        (Number(item.cashOndelivery_fee) +
                                          Number(item.shipping_cnfee) *
                                            details.rate_yuan)
                                    )
                                  }}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-12 table-container">
                      <table v-if="item.show">
                        <thead>
                          <tr>
                            <th class="p-1">สถานะ : ไม่ได้สั่งซื้อ</th>
                          </tr>
                        </thead>
                      </table>
                      <table
                        v-if="
                          details.status !== 1 &&
                          details.status !== 2 &&
                          details.status !== 3 &&
                          !item.show
                        "
                        width="100%"
                      >
                        <thead>
                          <tr>
                            <th class="p-1">
                              {{ $t("parcelCode") }}
                            </th>

                            <th class="text-center">
                              {{ $t("productLot") }}
                            </th>

                            <th class="text-center">
                              {{ $t("arrivedChinaWarehouse") }}
                            </th>

                            <th class="text-center">
                              {{ $t("leftChinaWarehouse") }}
                            </th>

                            <th class="text-center">
                              {{ $t("arrivedThaiWarehouse") }}
                            </th>

                            <th class="text-center">
                              {{ $t("x-x") }}
                            </th>

                            <th class="text-center">
                              {{ $t("key-133") }}
                            </th>

                            <th class="text-center">
                              {{ $t("amount") }}
                            </th>

                            <th class="text-center">
                              <!-- {{ $t('thb') }} -->
                              ค่านำเข้าจีน-ไทย
                            </th>

                            <!-- <th class="text-center">
                              {{ $t('thb-0') }}
                            </th> -->
                          </tr>
                        </thead>
                        <tbody
                          v-for="(itpost, indexP) in item.postnumber"
                          :key="indexP"
                        >
                          <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <input
                                  v-model="itpost.postnumber"
                                  class="form-control mt-1"
                                  type="text"
                                  disabled
                                />
                              </div>
                            </td>
                            <td class="text-center">
                              {{
                                itpost.lot_name
                                  ? `${itpost.lot_name}/${itpost.lot_order}`
                                  : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                itpost.come_chinesewarehouse
                                  ? itpost.come_chinesewarehouse
                                  : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                itpost.out_chinesewarehouse
                                  ? itpost.out_chinesewarehouse
                                  : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                itpost.come_thaiwarehouse
                                  ? itpost.come_thaiwarehouse
                                  : "-"
                              }}
                            </td>
                            <!-- <td class="text-center">
                              {{ itpost.out_thaiwarehouse ? itpost.out_thaiwarehouse : '-' }}
                            </td> -->
                            <td class="text-center">
                              {{ itpost.width ? Commas(itpost.width) : "-" }}x{{
                                itpost.long ? Commas(itpost.long) : "-"
                              }}x{{
                                itpost.height ? Commas(itpost.height) : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                itpost.weight
                                  ? Commas(
                                      itpost.weight * itpost.product_amount
                                    )
                                  : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                itpost.charge_cue
                                  ? CommasCue(
                                      itpost.charge_cue * itpost.product_amount
                                    )
                                  : "-"
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                itpost.charge_per_save
                                  ? Commas(itpost.charge_per_save)
                                  : "-"
                              }}
                            </td>
                            <!-- <td class="text-center">
                              {{ itpost.charge_per_cue ? Commas(itpost.charge_per_cue) : '-' }}
                            </td> -->
                          </tr>
                        </tbody>
                      </table>

                      <div class="mt-2 d-flex align-items-center">
                        <p
                          class="mb-0 font-weight-bolder"
                          style="color: red"
                          v-if="details.shops[index].remark_shops"
                        >
                          {{ $t("remarks") }} :
                          {{
                            details.shops[index].remark_shops
                              ? details.shops[index].remark_shops
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 gap-5">
              <div class="col-md-3">
                <div class="card-total">
                  <p class="font-weight-bolder">
                    {{ $t("shopCount") }}
                  </p>
                  <h3 class="font-weight-bolder">
                    {{ Commas(details.shops.length) }}
                  </h3>
                </div>
              </div>

              <div class="col-md-3">
                <div class="card-total">
                  <p class="font-weight-bolder">
                    {{ $t("numberOfproducts") }}
                  </p>
                  <h3 class="font-weight-bolder">
                    {{
                      Commas(
                        details.shops.reduce(
                          (acc, shop) =>
                            acc +
                            (shop.products.length > 0
                              ? shop.products.reduce(
                                  (bf, ele) => bf + ele.quantity,
                                  0
                                )
                              : 0),
                          0
                        )
                      )
                    }}
                  </h3>
                </div>
              </div>

              <div class="col-md-3">
                <div class="card-total">
                  <p class="font-weight-bolder">
                    {{ $t("exRate") }}
                  </p>
                  <h3 class="font-weight-bolder">
                    {{ Commas(details.rate_yuan) }}
                  </h3>
                </div>
              </div>

              <div class="col-md-3">
                <div class="card-total">
                  <p class="font-weight-bolder">
                    {{ $t("key-66") }} [¥ {{ $t("key-143") }}]
                  </p>
                  <h3 class="font-weight-bolder">
                    ¥
                    {{
                      Commas(
                        details.shops.reduce(
                          (acc, shop) =>
                            acc +
                            (shop.products.reduce(
                              (bf, ele) => bf + ele.price * ele.quantity,
                              0
                            ) +
                              shop.shipping_cnfee),
                          0
                        )
                      )
                    }}
                  </h3>
                </div>
              </div>

              <!-- <div class="col-md-3">
                <div class="card-total">
                  <p class="font-weight-bolder">
                    ยอดรวมสินค้าทั้งหมด [฿ เงินบาท]
                  </p>
                  <h3 class="font-weight-bolder">
                    ฿ {{ Commas(details.shops.reduce((acc, shop) => acc + (shop.products.reduce((bf, ele) => bf + (ele.price * ele.quantity), 0)), 0) * details.rate_yuan ) }}
                  </h3>
                </div>
              </div> -->
            </div>

            <div class="row mt-2">
              <div class="col-md-6" />
              <div class="col-md-6">
                <div class="card-info">
                  <h4 class="font-weight-bolder">
                    {{ $t("summaryListofexpenses") }}
                  </h4>
                  <hr />

                  <!-- <div>
                    <h4 class="font-weight-bolder">
                      {{ $t('specialServicecosts') }}
                    </h4>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('shippingInchina') }} ({{ $t('key-136') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(shipping_cnfee = details.shops.reduce((acc, shop) => acc + shop.shipping_cnfee, 0) * details.rate_yuan ) }}
                      </p>
                    </div>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('woodenCrates') }} ({{ $t('key-136') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(packaging_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc + shop.packaging_fee, 0)).reduce((re, res) => re + res,0) ) }}
                      </p>
                    </div>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('ProductInspectionfee') }} ({{ $t('key-136') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(product_inspection_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc + shop.product_inspection_fee, 0)).reduce((re, res) => re + res,0) ) }}
                      </p>
                    </div>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('key-138') }} ({{ $t('key-136') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(re_wrap_fee = details.shops.map(shop => shop.postnumber.reduce((acc, shop) => acc + shop.re_wrap_fee, 0)).reduce((re, res) => re + res,0) ) }}
                      </p>
                    </div>

                    <div class="d-flex justify-content-between pl-2 pr-2">
                      <p class="mb-0">
                        {{ $t('key-139') }} ({{ $t('key-136') }})
                      </p>

                      <p class="font-weight-bolder">
                        ฿ {{ Commas(cashOndelivery_fee = details.shops.reduce((acc, shop) => acc + shop.cashOndelivery_fee, 0) ) }}
                      </p>
                    </div>
                  </div> -->

                  <div class="d-flex justify-content-between">
                    <p>{{ $t("key-135") }} ({{ $t("key-136") }})</p>

                    <p class="font-weight-bolder">
                      ฿
                      {{
                        Commas(
                          (totalall =
                            details.shops.reduce(
                              (acc, shop) =>
                                acc +
                                shop.products.reduce(
                                  (bf, ele) => bf + ele.price * ele.quantity,
                                  0
                                ),
                              0
                            ) * details.rate_yuan)
                        )
                      }}
                    </p>
                  </div>

                  <p class="mb-0">
                    {{ $t("useDiscountCoupon") }} : {{ $t("none") }}
                  </p>

                  <hr />

                  <div class="d-flex justify-content-between">
                    <h3 class="font-weight-bolder">
                      {{ $t("key-66") }}
                    </h3>

                    <h3 class="font-weight-bolder text-primary">
                      {{
                        Commas(
                          details.shops.reduce(
                            (acc, shop) => acc + shop.shipping_cnfee,
                            0
                          ) *
                            details.rate_yuan +
                            packaging_fee +
                            product_inspection_fee +
                            re_wrap_fee +
                            cashOndelivery_fee +
                            totalall
                        )
                      }}
                      {{ $t("baht") }}
                      <!-- {{ Commas(details.price_total) }} บาท -->
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2 d-flex justify-content-between">
              <h3 class="mb-0">
                {{ $t("relatedAmountList") }}
              </h3>
              <span class="badge badge-light-success" style="font-size: 16px">
                {{ $t("availableBalance") }} {{ Commas(Balance) }}
              </span>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fields"
                :items="itemser"
                show-empty
              >
                <template #empty>
                  <b-table-empty-slot>
                    <div class="text-center my-2">
                      <img
                        src="@/assets/images/anan-img/svg/doc_empty.svg"
                        alt="empty"
                        height="60px"
                      />
                      <p class="text-muted">No Data</p>
                    </div>
                  </b-table-empty-slot>
                </template>
                <template #cell(amount)="data">
                  <span :style="getAmountStyle(data.item.type)">
                    {{ getAmountPrefix(data.item.type) }}
                    {{ Commas(data.item.amount.toFixed(2)) }} ฿
                  </span>
                </template>
              </b-table>
            </div>
            <div class="pl-2 pr-2 mb-2 text-danger" v-if="details.status === 1">
              1.เมื่อลูกค้าเปิดใบสั่งซื้อเข้ามาในระบบ ทางเจ้าหน้าที่จะสรุปยอดให้ลูกค้าภายใน 1 วัน (เวลาทำการวันจันทร์-เสาร์ 09.00-18.00 น.) กรณีที่ยังไม่ได้รับการสรุปยอด จะแจ้งเหตุผลให้ลูกค้าทราบทางไลน์<br>
              2.หากมีคำสั่งพิเศษเกี่ยวกับใบสั่งซื้อของลูกค้า โปรดหมายเหตุมาให้ชัดเจน กรณีสินค้าที่สั่งซื้อมาหมด ได้ไม่ครบตามที่สั่ง เราจะทำการตรวจสอบออเดอร์ให้ตามที่เราสามารถกดสั่งซื้อในเว็บนั้นๆให้ลูกค้าได้ <br>
              3.หากทางเราตรวจสอบแล้ว ท่านไม่ต้องการสั่งซื้อ กรุณาแจ้งยกเลิกภายใน 1-3 วัน เพื่อรักษาผลประโยชน์ของตัวท่านเอง
            </div>
            <div class="pl-2 pr-2 mb-2 text-danger" v-if="details.status === 2">
              1.เมื่อขึ้นสถานะ"รอชำระเงิน" ลูกค้ากดแจ้งชำระเงินเรียบร้อย ทางเจ้าหน้าที่จะทำการสั่งซื้อให้ภายใน 1-2 ชั่วโมง (ลูกค้าจะต้องชำระภายใน วันจันทร์-เสาร์ เวลา 09.00-18.00 น.) <br>
              2.หากเราตรวจสอบออเดอร์ให้แล้ว ลูกค้าไม่ชำระเงินภายใน 3 วัน ทางเราจะยกเลิกออเดอร์ของลูกค้าทันที <br>
              3.และหากท่านมีคำสั่งซื้อรอชำระเงิน เกินกว่า 10 คำสั่งซื้อใน 1 เดือน ทางเราจะยกเลิกออเดอร์ของท่าน และครั้งต่อๆไปคำสั่งซื้อของท่านจะได้รับการสรุปยอดช้า <br>
            </div>
            <div class="pl-2 pr-2 mb-2 text-danger" v-if="details.status === 4">
              1.กรณีสั่งซื้อเรียบร้อยแล้ว แต่ร้านยังไม่ส่งของ กรณีไม่สามารถติดต่อร้านได้เกิน 3 วัน ทางเจ้าหน้าที่จะกดยกเลิกขอเงินคืนโดยทันที (ขอสงวนสิทธิ์ในการแจ้งล่วงหน้า) <br> 
              2.กรณีร้านแจ้งต้องรอผลิตสินค้า, เลื่อนส่งของ มีระยะเวลาที่จะต้องรอการจัดส่ง ทางเจ้าหน้าที่จะแจ้งสอบถามลูกค้าทางไลน์
            </div>
            <div class="pl-2 pr-2 mb-2 text-danger" v-if="details.status === 5">
              1.กรณีร้านแจ้งเลขแทรค/พัสดุมาแล้ว แต่ไม่มีสถานะการจัดส่ง ทางเจ้าหน้าที่จะติดตามร้านค้า และอัพเดตข้อมูลแจ้งลูกค้าภายใน 5-7 วัน ถ้าหากไม่สามารถติดต่อร้านได้เกิน 5-7 วัน และเพื่อผลประโยชน์ของลูกค้า ทางเจ้าหน้าที่จะกดขอคืนเงินสินค้าให้ทันที (ขอสงวนสิทธิ์ในการแจ้งล่วงหน้า) <br>
              2.หากใบสั่งซื้อของท่านมีแทรค/พัสดุแล้ว ไม่เข้าโกดังจีนภายใน 5-7 วัน ลูกค้าติดตามใบสั่งซื้อของท่านทาง @anancargo 
            </div>
          </div>
        </b-col>
        <b-col md="12" v-if="details.status >= 6 && details.status !=7">
          <div class="card p-2">
          <div class="row gap-5">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <img src="/checkorder.jpg" alt="" width="100%" height="100%" />
            </div>
            <div class="col-md-3"></div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-modal ref="my-modal" :title="$t('orderRate')" size="sm">
        <b-form-group>
          <label for="rate">{{ $t("exRate") }}</label>
          <b-form-input
            id="rate"
            v-model="details.rate_yuan"
            type="number"
            :placeholder="$t('exRate')"
          />
        </b-form-group>
      </b-modal>

      <b-modal
        ref="my-remark"
        :title="$t('key-140')"
        size="sm"
        @ok="UpdateDetail()"
      >
        <b-form-group>
          <label for="remark">{{ $t("remarks") }}</label>
          <b-form-textarea id="remark" v-model="remark_shops" type="text" />
        </b-form-group>
      </b-modal>

      <b-modal
        ref="my-remark2"
        :title="$t('key-144')"
        size="sm"
        @ok="UpdateDetail()"
      >
        <b-form-group>
          <label for="remark">{{ $t("remarks") }}</label>
          <b-form-textarea id="remark" v-model="remark_product" type="text" />
        </b-form-group>
      </b-modal>

      <b-modal ref="my-color" :title="$t('key-141')" size="sm" hide-footer>
        <div class="d-flex justify-content-center align-items-center">
          <div
            style="
              width: 50px;
              height: 50px;
              background-color: rgb(255, 255, 255);
              border: 1px solid#ccc;
            "
            class="mr-1"
            @click="selectedColor('rgb(255, 255, 255)')"
          />

          <div
            style="
              width: 50px;
              height: 50px;
              background-color: rgb(241, 241, 241);
            "
            class="mr-1"
            @click="selectedColor('rgb(241, 241, 241)')"
          />

          <div
            style="
              width: 50px;
              height: 50px;
              background-color: rgb(209, 231, 251);
            "
            class="mr-1"
            @click="selectedColor('rgb(209, 231, 251)')"
          />

          <div
            style="
              width: 50px;
              height: 50px;
              background-color: rgb(230, 253, 205);
            "
            class="mr-1"
            @click="selectedColor('rgb(230, 253, 205)')"
          />

          <div
            style="
              width: 50px;
              height: 50px;
              background-color: rgb(255, 231, 231);
            "
            class="mr-1"
            @click="selectedColor('rgb(255, 231, 231)')"
          />
        </div>
      </b-modal>
      <vue-image-lightbox-carousel
        ref="lightbox"
        :show="showLightbox"
        :images="images"
        :show-caption="false"
        @close="closeBox()"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BLink,
  BBadge,
  // BFormSelect,
  BFormCheckbox,
  // BButton,
  VBTooltip,
  BTable,
} from "bootstrap-vue";
import moment from "moment-timezone";
// import vSelect from 'vue-select'
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import "vue-context/dist/css/vue-context.css";
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BModal,
    // BFormSelect,
    BTable,
    BLink,
    BFormCheckbox,
    // BButton,
    // ThisHeader,
    VueImageLightboxCarousel,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      itemsRelevant: [],
      indexShop: null,
      indexProduct: null,
      checked: null,
      rate: null,
      color: null,
      name: "",
      category: "",
      code: "",
      details: "",
      type: null,
      typesOp: [
        { value: null, text: "ทั้งหมด" },
        { value: "1", text: "A" },
        { value: "2", text: "B" },
        { value: "3", text: "C" },
      ],
      remark_shops: "",
      remark_product: "",
      selectedFiles: [],
      uploadedImages: [],

      items: [
        { create_date: "00/00/00 00:00:00", detail: "ฟหกฟหกฟหก", amount: "1" },
        { create_date: "00/00/00 00:00:00", detail: "ฟหกฟหกฟหก", amount: "1" },
        { create_date: "00/00/00 00:00:00", detail: "ฟหกฟหกฟหก", amount: "1" },
      ],
      itemser: [],
      PostNumberArr: [],
      shipping_cnfee: 0,
      packaging_fee: 0,
      product_inspection_fee: 0,
      re_wrap_fee: 0,
      cashOndelivery_fee: 0,
      totalall: 0,
      Balance: 0,
      images: [],
      showLightbox: false,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "created_at",
          label: this.$t("transactionDate"),
          thStyle: { width: "20%" },
          formatter: (value) => moment(value).format("DD/MM/YYYY HH:mm:ss"),
        },
        { key: "detail", label: this.$t("details"), thStyle: { width: "60%" } },
        {
          key: "amount",
          label: this.$t("quantity"),
          thStyle: { width: "20%" },
        },
      ];
    },
    fieldsRelevant() {
      return [
        {
          key: "ship",
          label: this.$t("transactionDate"),
          thStyle: { width: "20%" },
        },
        { key: "kg", label: this.$t("details"), thStyle: { width: "60%" } },
        {
          key: "amount",
          label: this.$t("quantity"),
          thStyle: { width: "20%" },
        },
      ];
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  async mounted() {
    // await this.GetRate()
    this.GetOrderDetail();
    await this.GetTransaction();
    // console.log(this.userData)
    this.GetBalance();
  },
  methods: {
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },
    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: picture };
      this.images.push(img);
    },
    showPicturePost(pictures) {
      this.showLightbox = true;
      pictures.forEach((picture) => {
        const img = { path: this.GetImg("postnumber_img", picture) };
        this.images.push(img);
      });
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    async GetBalance() {
      try {
        const { data: res } = await this.$http.get("/Balance/getbalanceOne");
        this.Balance = res.balance;
      } catch (err) {
        console.log(err);
      }
    },
    Approve(data) {
      this.details.status = data + 1;
      this.details.inspector = this.userData.name;
      this.UpdateDetail();
    },

    UpdateDetail() {
      if (this.remark_shops && this.IndexRemark >= 0) {
        this.details.shops[this.IndexRemark].remark_shops = this.remark_shops;
      }
      if (
        this.indexShop >= 0 &&
        this.indexProduct >= 0 &&
        this.remark_product
      ) {
        this.details.shops[this.indexShop].products[
          this.indexProduct
        ].remark_product = this.remark_product;
      }
      const params = {
        data: this.details,
      };
      // console.log(params)
      this.$http
        .post("/order/UpdateOrderCus", params)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.Success(this.$t("key-260"));
          this.GetOrderDetail();
          this.remark_shops = null;
          this.IndexRemark = null;
          this.indexShop = null;
          this.indexProduct = null;
          this.remark_product = null;
          // console.log(response.data)
        })
        .catch((error) => console.log(error));
    },
    GetRate() {
      this.$http
        .get("/rate")
        .then((response) => {
          // console.log(response.data)
          this.rate = response.data.rate_preorder;
        })
        .catch((error) => console.log(error));
    },
    Pay(data) {
      this.$swal({
        title: "ยืนยันที่จะชำระเงิน ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.show = true;
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID: data._id,
          };
          this.$http
            .post("/order/confirmpay", params)
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              // console.log(response.data)
              this.Success("ชำระเงินสำเร็จ");
              this.show = false;
              this.GetOrderDetail();
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
              this.show = false;
            });
        } else {
          this.$swal("ยกเลิก", "ยกเลิกคำสั่ง !", "error");
        }
      });
    },
    CancelOrder(ID) {
      this.$swal({
        title: "ยืนยันที่จะยกเลิกรายการ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.show = true;
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID,
          };
          this.$http
            .post("/order/cancelOrder", params)
            // eslint-disable-next-line no-unused-vars
            .then((response) => {
              // console.log(response.data)
              this.Success("ยกเลิกรายการสำเร็จ");
              this.show = false;
              this.GetOrderDetail();
            })
            .catch((error) => {
              this.SwalError(error.response.data.message);
              this.show = false;
            });
        } else {
          this.$swal("ยกเลิก", "ยกเลิกคำสั่ง !", "error");
        }
      });
    },
    async GetOrderDetail() {
      const params = {
        ID: this.$route.params.id,
      };
      this.$http
        .post("/order/OrderById", params)
        .then((response) => {
          // console.log(response.data)
          this.details = response.data;
          this.details.created_at = moment(response.data.created_at).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          this.details.updated_at = moment(response.data.updated_at).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          // eslint-disable-next-line
          for (const index in this.details.shops) {
            this.details.shops[index].postnumber = [];
            this.CalPostNumber(this.details.shops[index], index);
          }
        })
        .catch((error) => console.log(error));
    },
    CalPostNumber(item, ShopIndex) {
      const FindShop = this.PostNumberArr.find(
        (ele) => ele.ShopIndex === ShopIndex
      );
      if (FindShop) {
        this.details.shops[ShopIndex].postnumber = FindShop.item;
      } else {
        this.GetPostNumber(item, ShopIndex);
      }
    },
    async GetPostNumber(item, ShopIndex) {
      try {
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          shop_id: item._id,
        };
        const { data: res } = await this.$http.get("/postnumber/byshop", {
          params,
        });
        if (res.success) {
          this.details.shops[ShopIndex].postnumber = res.data;
          this.details = { ...this.details };
          const obj = { ShopIndex, item: res.data };
          this.PostNumberArr.push(obj);
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16, 18].includes(amount)) {
          return { color: "red" };
        }
        if (amount && [8, 11, 12, 17].includes(amount)) {
          return { color: "green" };
        }
      }
      return { color: "orange" };
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16, 18].includes(value)) {
          return "-";
        }
        if (value && [8, 11, 12, 17].includes(value)) {
          return "+";
        }
      }
      return "";
    },
    async GetTransaction() {
      try {
        const params = {
          ref_id: this.$route.params.id,
        };
        const { data: res } = await this.$http.get("/transactions/show/ref", {
          params,
        });
        if (res.success) {
          this.itemser = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    ChangePostNumber(indexShop, postnumber) {
      this.details.shops[indexShop].postnumber = postnumber;
      this.UpdateDetail();
    },
    showColor(indexShop, indexProduct) {
      // colorfield
      this.indexShop = indexShop;
      this.indexProduct = indexProduct;
      this.$refs["my-color"].show();
    },
    selectedColor(color) {
      // change color by index
      this.details.shops[this.indexShop].products[
        this.indexProduct
      ].colorfield = color;
      this.UpdateDetail();
      this.$refs["my-color"].hide();
    },
    addAddr() {
      this.$refs.addRules.validate().then((success) => {
        if (success) {
          this.$bvModal.hide("add-addr");
        }
      });
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    showRemark(index) {
      this.remark_shops = "";
      this.IndexRemark = index;
      if (this.details.shops[this.IndexRemark].remark_shops) {
        this.remark_shops = this.details.shops[this.IndexRemark].remark_shops;
      }
      this.$refs["my-remark"].show();
    },
    showRemark2(indexShop, indexProduct) {
      this.remark_product = "";
      this.indexShop = indexShop;
      this.indexProduct = indexProduct;
      if (this.details.shops[indexShop].products[indexProduct].remark_product) {
        this.remark_product =
          this.details.shops[indexShop].products[indexProduct].remark_product;
      }
      this.$refs["my-remark2"].show();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-context.scss";

@media only screen and (max-width: 600px) {
  .gap-5 {
    gap: 10px !important;
  }
  .table-container {
    font-size: 12px !important;
  }
}
</style>
